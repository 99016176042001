import {get, postJ} from "@/request";
import {IProduct, IProductQuery} from "@/apis/product/list/types";
import { IPageRes} from "@/apis/page";
import {IOrderRefundedQuery} from "@/apis/afterSales/refunded/types";

/**
* 获取列表
*/
export const getProductPageApi = (param:IProductQuery) => get<IPageRes<IProduct[]>>("/mall/goods/query", param)
/**
* 添加
*/
export const createProductApi = (data: IProduct) => postJ("/mall/goods/create", data)
/**
* 修改
*/
export const modifyProductApi = (data: IProduct) => postJ("/mall/goods/modify", data)
/**
 * 修改排序
 */
export const modifyProductSortApi = (data: IProduct) => postJ("/mall/goods/modify/sort", data)
/**
* 查询详情
* @param id
*/
export const getProductDetailApi = (id: string) => get<IProduct>("/mall/goods/detail/" + id)

/**
 * 删除
 * @param id
 */
export const modifyProductRemoveApi = (id: string) => get<IProduct>(`/mall/goods/remove/${id}` )


/**
 * 导出
 * @param IProductQuery
 * @returns
 */
export const goodsExportApi = (params:IProductQuery) => postJ("/mall/goods/exportGoods", params,'blob');

/**
 * 切换状态
 * @param id
 */
export const modifyProductStatusApi = (id: string) => get<IProduct>(`/mall/goods/setUpStatus/${id}` )

/**
 * 下拉产品列表
 *
 */
export const getProductListApi = () => get<IProduct[]>("/mall/goods/dropDown" )
