
import {Component, Vue} from "vue-property-decorator";
import {ICrudOption} from "@/types/m-ui-crud";
import {
  createProductApi,
  getProductDetailApi,
  getProductPageApi,
  goodsExportApi,
  modifyProductApi,
  modifyProductRemoveApi,
  modifyProductSortApi,
  modifyProductStatusApi
} from "@/apis/product/list";
import {IProduct, IProductQuery} from "@/apis/product/list/types";
import {Message} from "element-ui";
import config from "@/config";
import {exportFile} from "@/utils/common";
import {productSortListApi} from "@/apis/product/sort";

@Component({})
export default class ProductPage extends Vue {

  config = config;
  //查询条件
  queryForm: IProductQuery = {
    productSorting: 1,
    page: 1,
    limit: 10
  }
  total: number = 0

  //列表数据
  tableData: IProduct[] = []

  // 新增修改表单
  modelForm: IProduct = {}

  labelList: any[] = []
  inputValue: string = ''

  previewDialog: boolean = false;
  previewUrl: string = `${location.protocol}//${location.hostname}:81/h5/pages_product/pages/detail/detail`

  sortDialog: boolean = false
  rules: any =
    {
      goodsSort: [
        {
          required: true, trigger: 'blur', validator: (rule: any, value: any, callback: any) => {
            if (value === null || value === "" || value === undefined) {
              return callback(new Error('请输入排序1~999999!'))
            }
            callback()
          }
        }
      ],
    }

  /**
   * 监听iframe传过来的初始化
   */
  onMessage(event: any) {
    const {data} = event;
    const iframe: any = document.getElementById('iframe');
    if (data.type === "load") {
      this.modelForm.goodsLabel = this.labelList.join(',');
      console.log(this.modelForm, 'this.modelForm')
      // {goodsName: this.modelForm.goodsName||'' + new Date().getTime()}}
      iframe.contentWindow.postMessage({type: 'preview', data: this.modelForm}, '*');
    }
  }

  /**
   * 打开 iframe
   */
  openPreview() {
    // window.addEventListener('message', this.onMessage);
    let url = ""
    if (process.env.NODE_ENV == 'development') {
      // 开发模式
      url = `${location.protocol}//${location.hostname}:81/h5/pages_product/pages/detail/detail`
    } else {
      url = `/h5/pages_product/pages/detail/detail`
    }
    this.previewUrl = url + "?t=2" + "&time=" + new Date().getTime()
    this.previewDialog = true;
    this.$nextTick(() => {
      window.addEventListener('message', this.onMessage);
    })
  }

  /**
   * 关闭 iframe
   */
  closePreview(done: Function) {
    const iframe: any = document.getElementById('iframe');
    iframe.contentWindow.postMessage({type: 'close'}, '*');
    window.removeEventListener("message", this.onMessage)
    done()
  }


  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: ICrudOption = {
    dialogWidth: "50%",
    searchBox: true,
    menu: true,
    editBtn: true,
    delBtn: true,
    addTitle: "新增商品",
    editTitle: "编辑商品",
    labelWidth: '150px',
    column: [
      {
        label: "商品",
        prop: "goodsName",
        placeholder: "请输入商品ID/商品名称",
        align: 'center',
        search: true,
        hide: true,
        addHide: true,
        editHide: true,
        viewHide: true
      },
      {
        label: "ID",
        prop: "id",
        align: 'center',
        addHide: true,
        editHide: true,
        slot: true,
        width: 200,
        span: 20,
      },
      {
        label: "商品名称",
        prop: "goodsName",
        align: 'center',
        maxlength: 20,
        rules: [
          {required: true, message: '请输入商品名称', trigger: 'blur'},
        ],
        span: 20,
      },
      {
        label: "商品属性",
        prop: "goodsTypes",
        align: 'center',
        search: true,
        type: 'select',
        dicData: [
          {
            label: "套餐",
            value: 2
          },
          {
            label: "单品",
            value: 1
          },
        ],
        rules: [
          {required: true, message: '请输入商品属性', trigger: 'blur'},
        ],
        span: 20,
      },
      {
        label: "商品分类",
        prop: 'productSortId',
        hide: true,
        search: true,
        placeholder: "请选择商品分类",
        type: "select",
        dicUrl: '/api/admin/productSort/dropDown',
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'id'}),
        rules: [
          {required: true, message: '请选择商品分类', trigger: 'blur'}
        ],
        viewHide: true,
        span: 20,
      },
      {
        label: "商品分类",
        prop: 'sortName',
        align: "center",
        addHide: true,
        editHide: true,
        span: 20,
      },
      {
        label: "商品售价",
        prop: "goodsPrice",
        align: 'center',
        maxlength: 10,
        rules: [
          {
            required: true,
            validator: (rule: any, value: any, callback: any) => {
              if (!value) {
                return callback(new Error("请输入商品售价"));
              } else if ((parseFloat(value) <= 0)||(parseFloat(value) > 999999.99) || !/(^[0-9]\d{0,6}(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
                callback(new Error("请输入商品售价大于0，2位小数，最大数值999999.99!"));
              } else {
                callback();
              }
            }, trigger: 'blur'
          },
        ],
        slot: true,
        addSlot: true,
        editSlot: true,
        viewSlot: true,
        span: 20,
      },
      {
        label: "商品介绍标签",
        prop: "goodsLabel",
        align: 'center',
        width: 180,
        slot: true,
        viewSlot: true,
        addSlot: true,
        editSlot: true,
        rules: [
          {required: false, message: '请输入商品介绍标签', trigger: 'blur'},
        ],
        span: 24,
      },
      {
        label: "商品活动",
        prop: "productActivities",
        align: 'center',
        width: 180,
        slot:true,
        viewHide: true,
        addHide:true,
        editHide:true,
        span: 24,
      },
      {
        label: "商品活动",
        prop: "activity",
        align: 'center',
        hide: true,
        addHide: true,
        editHide: true,
        width: 180,
        span: 24,
      },
      {
        label: "排序",
        prop: "goodsSort",
        align: 'center',
        // @ts-ignore
        sortable: "custom",
        slot: true,
        viewHide:true,
        addHide:true,
        editHide:true
      },
      {
        label: "商品图片",
        prop: "goodsPic",
        type: 'image',
        align: 'center',
        addSlot: true,
        editSlot: true,
        imgPrefix: config.downloadUrl,
        imgSuffix: ';',
        rules: [
          {required: true, message: '请上传商品图片', trigger: 'blur'},
        ],
        span: 24,
      },
      {
        label: "商品视频",
        prop: "goodsVideo",
        align: 'center',
        hide: true,

        addSlot: true,
        editSlot: true,
        viewSlot: true,
        span: 24,
      },
      {
        label: "商品详情",
        prop: "goodsDetails",
        align: 'center',
        search: false,
        hide: true,
        addSlot: true,
        editSlot: true,
        viewSlot: true,
        span: 24,
        rules: [
          {required: true, message: '请输入商品详情', trigger: 'blur'},
        ],
      },
      {
        label: "效果预览",
        prop: "btn",
        align: 'center',
        hide: true,
        addSlot: true,
        editSlot: true,
        viewSlot: true
      },
      {
        label: "商品状态",
        prop: "status",
        align: 'center',
        search: true,
        type: 'switch',
        slot: true,
        dicData: [
          {
            label: "下架",
            value: 2
          },
          {
            label: "上架",
            value: 1
          },
        ],
        addHide: true,
        editHide: true,
        viewHide: true
      },
    ]
  }

  /**
   * 获取列表
   */
  getList() {
    getProductPageApi(this.queryForm).then(e => {
      this.tableData = e.list;
      this.total = e.total
    })
  }

  /***
   * 分类下拉
   */
  getProductSortList() {
    productSortListApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("productSortId", {
        dicData: e.map((item: any) => {
          return {
            label: item.sortName,
            value: item.id
          }
        })
      })
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("productSortId", {
        dicData: e.map((item: any) => {
          return {
            label: item.sortName,
            value: item.id
          }
        })
      })
    })
  }

  /**
   * 列表 排序改变
   * @param e
   */
  sortChange(e: any) {
    this.queryForm.page = 1;
    if (e.order === "ascending") {
      // 升序
      this.queryForm.productSorting = 1
    } else {
      // 降序
      this.queryForm.productSorting = 2
    }
    this.getList()
  }

  /**
   * 修改排序弹窗
   * @param  item: any, index: number
   */
  handleSort(item: any, index: number) {
    this.modelForm = item;
    this.sortDialog = true
  }

  /**
   * 排序修改
   */
  handleSortAffirm() {
    modifyProductSortApi(this.modelForm).then(e => {
      if (e) {
        this.$message.success('修改成功！')
        this.sortDialog = false
        this.getList()
      }
    })
  }

  /**
   * 新增提交
   * @param  form: any, done: any, loading: any
   */
  submitSave(form: any, done: any, loading: any) {
    form.goodsLabel = this.labelList.join(',');
    createProductApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 修改提交
   * @param  form: any, done: any, loading: any
   */
  submitEdit(form: any, done: any, loading: any) {
    form.goodsLabel = this.labelList.join(',');
    modifyProductApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 修改前
   * @param  item: any, index: number
   */
  handleShowEidt(item: any, index: number) {
    // this.getProductSortList();
    getProductDetailApi(item.id).then(e => {
      this.labelList = e.goodsLabel && e.goodsLabel != '' ? e.goodsLabel?.split(',') || [] : [];
      // @ts-ignore
      this.$refs.crudRef.rowEdit(e, index);

    })
  }

  /**
   * 删除提交
   */
  submitRemove(form: any, done: any, loading: any) {
    modifyProductRemoveApi(form.id).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    })
  }

  rowAdd() {
    this.labelList = [];
    // this.getProductSortList();
    // @ts-ignore
    this.$refs.crudRef.rowAdd();

  }

  /**
   * 切换状态
   * @param id
   */
  handleSwitch(id: string) {
    modifyProductStatusApi(id).then(e => {
      if (e) {
        Message.success("操作成功!")
        this.getList()
      }
    })
  }

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
    const isLt = file.size / 1024 / 1024 < 0.5;
    if (!isLt) {
      this.$message.error(`上传图片大小不能超过 0.5MB!`);
      return false;
    }
  }

  /**
   * 上传前检验
   * @param file
   */
  beforeVideoUpload(file: File) {
    if (file.name.split('.')[1].toLowerCase() != 'mp4') {
      Message.error("请选择正确格式")
      return false
    }
    const isLt = file.size / 1024 / 1024 < 100;
    if (!isLt) {
      this.$message.error(`上传图片大小不能超过 100MB!`);
      return false;
    }
  }

  /**
   * 导出excel
   * 商品数据
   */
  exportExcel () {
    goodsExportApi(this.queryForm).then(e => {
      exportFile(e, "商品信息.xlsx")
    })
  }

  /**
   * 打开详情
   * @param item
   * @param index
   */
  openDetail(item: any, index: number) {
    getProductDetailApi(item.id).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowView(e, index)
    })
  }

  /**
   * 回车添加标签
   * @param tag
   */
  handleInputConfirm() {
    let inputValue = this.inputValue;
    if (inputValue) {
      this.labelList.push(inputValue);
    }
    this.inputValue = '';
  }

  /**
   * labelList 关闭标签
   * @param tag
   */
  handleClose(tag: string) {
    this.labelList.splice(this.labelList.indexOf(tag), 1);
  }

  created() {
    // this.getProductSortList();
    this.getList();
  }

  mounted() {
    window.addEventListener('message', this.onMessage);
  }
}
